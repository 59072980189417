<template>
  <div class="container custom-scroller">
    <div class="answerSheetContainer custom-scroller"
         ref="questionnaireContent"
         @scroll="showTipBottomVeil = $event.target.scrollTop == 0 ? false : true"
    >
      <el-collapse v-if="groupAnswerSheets.length" v-model="previewActiveGroups" class="groupQuestionCollapse">

        <el-collapse-item  v-for="(group,index) in groupWithUnassigned" :key="group.id" :title="group.name" :name="group.id" v-show="groupVisible[index]">
          <template #title>
            <img src="../../../assets/images/groupMenu.png" class="groupMenuIcon"/>{{group.name}}
          </template>
          <doc-question-answer-mode v-for="(answer,j)  in groupAnswerSheets[index]" :key="j"
                                    v-show="groupAnswerSheetVisible[index][j]"
                                    :isPreview="true"
                                    :answerSheet="answer" :autoSave="false" :canSearch="false"
                                    :showVariable="true">
          </doc-question-answer-mode>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import DocQuestionAnswerMode from "../../../components/DocQuestionAnswerMode";
import {multiple, single} from "../../../components/common/question";
import {getContractViewDetail, requestHeaderMixin} from "../../../api/api";
import {ContractApprovalStatus} from "../../../constant/contract";
import {CommentPermissionMode} from "../../../components/common/constant";
import {ContractFileType} from "../../../components/common/contractConstant";
import {answerSheetShouldShowQuestion} from "../../../constant/question";

export default {
  name: "MobileAnswerSheetReview",
  components: {DocQuestionAnswerMode},
  mixins:[requestHeaderMixin],
  mounted() {
    this.parseParam();
  },
  data(){
    return {
      showTipBottomVeil:false,
      groups:[],
      previewActiveGroups: [],
      groupWithUnassigned: [],
      answerSheet: [],
      groupVisible:[],
      groupAnswerSheetVisible:[],
    }
  },
  computed:{
    answerSheetMap: function () {
      return new Map(this.answerSheet.map(a => [a.id, a]))
    },
    groupAnswerSheets: function () {
      if(!this.answerSheet){
        return [];
      }
      const groupAnswerSheetMap = new Map();
      const answerSheets = this.answerSheet
      for (let i = 0; i < answerSheets.length; i++) {
        let answerSheet = answerSheets[i];
        const groupId = answerSheet.groupId?answerSheet.groupId:-1;
        if (!groupAnswerSheetMap.has(groupId)) {
          groupAnswerSheetMap.set(groupId, []);
        }

        answerSheet.question =  {
          title: answerSheet.title,
          showControlAnsId:answerSheet.showControlAnsId || null,
          showControlAction:answerSheet.showControlAction || null,
          showControlRelationshipType:answerSheet.showControlRelationshipType || null,
          showControlAnsVal:answerSheet.showControlAnsVal,
          answerType:answerSheet.answerType,
        }
        //如果是单选或多选类型,加上options属性
        if(single(answerSheet) || multiple(answerSheet)){
          answerSheet.question.options = JSON.parse(answerSheet.options);
        }
        answerSheet.answer = {
          content: multiple(answerSheet) ? JSON.parse(answerSheet.content) : answerSheet.content
        }

        groupAnswerSheetMap.get(groupId).push(answerSheet);
      }


      return this.groupWithUnassigned.map(group=>{
        const values = groupAnswerSheetMap.get(group.id)

        return values ? values : [];
      })
    },
  },
  methods:{
    parseParam: function () {
      let contractId = this.$route.query.id;
      return getContractViewDetail(contractId).then((res) => {
        console.log('getContractViewDetail   res:', res);
        if (res.data.code === 0) {
          let question = res.data.data.questionAndAnswer?JSON.parse(res.data.data.questionAndAnswer):null;
          const questionAndAnswer = question?question.answers:null;
          const groups = question?question.groups:null;
          if(!questionAndAnswer || !groups){
            this.groupWithUnassigned = [{id:-1,name:'未分组'}];
          }else{
            this.groupWithUnassigned = Array.from(new Set(questionAndAnswer.map((item) => item.groupId))).map((_item)=>({id:_item,name:groups.find(g=>g.id == _item).name})).concat({id:-1,name:'未分组'});
          }
          this.previewActiveGroups = this.groupWithUnassigned.map(item => item.id);

          this.answerSheet = questionAndAnswer
          //这个方法计算的属性跟上面的answerSheet是关联的
          this.reCalculateGroupAnswerSheetVisible();
        }
      });
    },
    reCalculateGroupAnswerSheetVisible: function () {
      let sheetSize = this.groupAnswerSheets.length;
      if(!sheetSize){
        return;
      }
      let groupAnswerSheetVisible = [];
      let groupVisible = [];
      for (let i = 0; i < sheetSize; i++) {
        let groupAnswerSheet = this.groupAnswerSheets[i];
        let groupQuestionSize = groupAnswerSheet.length;
        groupAnswerSheetVisible.push([]);
        let currentGroupVisible = false;
        for (let j = 0; j < groupQuestionSize; j++) {
          let answerSheet = this.groupAnswerSheets[i][j];
          let questionVisible = this.showQuestion(answerSheet);
          groupAnswerSheetVisible[i].push(questionVisible);
          if (questionVisible) {
            currentGroupVisible = true;
          }
        }
        groupVisible.push(currentGroupVisible);
      }
      this.groupAnswerSheetVisible = groupAnswerSheetVisible;
      this.groupVisible = groupVisible;
    },
    showQuestion:function(answerSheet){
      // console.log('************new show question*****')

      return answerSheetShouldShowQuestion(answerSheet, this.answerSheetMap);
    },
  }
}
</script>

<style scoped>
.answerSheetContainer{
  z-index:11;
  overflow-y:auto;
  padding:0px 10px 10px 10px;
  background: #fff;
  scroll-width:thin;
}
</style>
